
.sendButton {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 40px;
    transform: translateY(-50%);
    border-radius: 10px;
}

.sendButton:hover {
    opacity: 1;
}
