.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .dashboardButton {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background-color: #4caf50;
    color: #fff;
  }
  
  .newGrantButton {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background-color: #2196f3;
    color: #fff;
  }
  
  button:hover {
    opacity: 0.8; 
  }
  
  button:focus {
    outline: none; 
  }
  