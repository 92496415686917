.divider-green {
  width: 100%;
  padding: 5px;
  background-color: #c9e265;
}

.first-slide {
  padding-top: 25vh;
  padding-bottom: 35vh;
  color: black;
}

.type-animation-text {
  font-size: 100px;
  white-space: pre-line;
  height: 195px;
  display: block;
  text-decoration: underline;
  text-decoration-color: #c9e265;
}

.type-animation-text-mobile {
  font-size: 50px;
}

.second-slide, .third-slide, .fourth-slide {
  padding-top: 150px;
  padding-bottom: 150px;
}

.second-slide-mobile {
  padding-top: 75px;
  padding-bottom: 100px;
}

.third-slide-mobile, .fourth-slide-mobile {
  padding-top: 50px;
  padding-bottom: 75px;
}

.emphasized-text {
  text-decoration: underline;
  text-decoration-color: #c9e265;
  font-weight: bold;
}

.extra-padding-right {
  padding-right: 60px;
}

.custom-margin {
  margin-bottom: 0;
  margin-left: 20px;
}

.my-home-container-max-width {
  /* Based on https://youtube.com/shorts/SGlpOnIgk1w?feature=share */
  width: min(100% - 2rem, 1000px);
  margin-inline: auto;  
}

.home-bullet-img {
  max-width: 40px;
  float: left;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.button-container {
  margin-top: 2rem; /* Adjust as necessary */
  text-align: center;
}

.placeholder-button {
  margin: 7.5rem;
  padding: 1rem 2rem;
  font-size: 1.2rem; /* Adjust to match your design */
  background-color: #c9e265;
  color: black;
  border: 3px solid black;
  border-radius: 10px;
  cursor: pointer;
  animation: fadeIn 1.5s ease-in forwards;
  animation-delay: 2.25s;
  opacity: 0;
}

/* You can add :hover states for the buttons if desired */
.placeholder-button:hover {
  /* hover state styles */
}
