.not-found-container {
  padding-top: 5vh;
  text-align: center;
}

.big-text {
  font-size: 40px;
}

.dog-pic {
  width: min(400px, 100vw - 2rem);
  border-radius: 5px;
  margin: 5px;
}
