@font-face {
    font-family: "Roxborough CF Bold";
    src: url("./assets/fonts/roxborough-cf-bold.ttf");
  }
  
  @font-face {
    font-family: "Lora";
    src: url("./assets/fonts/Lora-Regular.ttf");
  }
  
  @font-face {
    font-family: "Glacial Indifference";
    src: url("./assets/fonts/GlacialIndifference-Regular.otf");
  }

body {
  margin: 0;
  font-family: var(--font-family-glacial), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
    font-size: larger;
}

