

.rootEditMode {
  --input-height: 60px;
  --message-text-font-size: 0.8rem;
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
}

.rootViewMode {
  border: 2px solid #6B8E23;
  box-shadow: 0 0 10px rgba(107, 142, 35, 0.5);
  background-color: #FAFAD2;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.rootViewMode:hover {
  border-color: #ADFF2F;
  box-shadow: 0 0 15px rgba(173, 255, 47, 0.8);
}


.listGroup {
    /* height: calc(85% - var(--input-height, 0)); */
    flex: 1;
    overflow-y: scroll;

}

/* .listGroup li:last-child {
  flex: 1;
} */

.input {
  height: var(--input-height);
  font-size: var(--message-text-font-size) !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: 0 -1px 4px 2px rgba(0, 0, 0, 0.1) !important;
}

.userMessage {
  transition: 0.1s;
  background-color: var(--bs-primary-light);
}

.wordLimitControl {
  // box-shadow: none !important;
  width: 80px;
}
