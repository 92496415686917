.title {
  transition: background-color 0.3s;
  outline: none;
}

.title:hover,
.title:focus,
.title:active {
  background-color: var(--bs-gray-200);
}

.title:focus {
  border-bottom: 1px solid var(--bs-gray-500);
  padding: 5px;
}
