@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/_variables";

.debug * {
  border: 1px solid red;
}

.content {
  flex: 1;
  width: 100%;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  max-height: 70vh;
}

.questionsWrapper {
  overflow: auto;
}

@include media-breakpoint-down(md) {
  .content {
    display: flex;
    flex-direction: column;
  }
  .content > * {
    min-height: 100%;
  }
  .questionsWrapper {
    overflow: visible;
  }
}


.mediumText {
  font-size: 30px;
}

.publicoDemoIframeContainer {
  align-items: center;
  justify-content: center;
  display: flex;
}

.publicoDemoIframe {
  width: min(1025px, 100% - 2rem);
  height: calc(100vh - 73px - 4rem);
  padding-bottom: 25px;
  padding-top: 5px;
}
