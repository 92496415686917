.my-contact-container {
  padding-top: 30px;
  padding-bottom: 50px;
  /* Based on https://youtube.com/shorts/SGlpOnIgk1w?feature=share */
  width: min(100% - 2rem, 600px);
  margin-inline: auto;  
}

.align-card-btn-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.form-sent-alert {
  padding-top: 10px;
  font-family: 'Lora';
}
