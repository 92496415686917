.how-it-works-container {
  padding-top: 30px;
}

.how-it-works-paragraph {
  /* Based on https://youtube.com/shorts/SGlpOnIgk1w?feature=share */
  width: min(100% - 2rem, 600px);
  margin-inline: auto;
}

.how-it-works-video-container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.how-it-works-img, .how-it-works-video {
  width: min(900px, 100% - 2rem);
  height: auto;
  padding: 1px;
  background-color: #c9e265;
  border-radius: 5px;
  margin-bottom: 25px;
  margin-top: 5px;
}

.how-it-works-img {
  padding: 0px;
}
