.my-header {
  width: 100%;
  padding: 1rem 1rem;
  height: 73px;
  border: 4px;
  border-color: #c9e265;
}

.my-logo {
  height: 61px;
  margin-top: -0.4rem;
  float: left;
}

.my-logo-mobile {
  margin-left: -1rem;
}

.my-navlink, .my-hamburger, .my-navlink-mobile {
  float: right;
  margin-left: 5px;
  font-family: var(--font-family-glacial);
  font-weight: bolder;
  font-size: larger;
  color: black;
  text-decoration: none;
  padding: 10px 20px;
}

.my-navlink-dark {
  background-color: black;
  color: #eee;
  border-radius: 5px;
  border: 1px solid black;
}

.my-navlink-dark:hover, .active:not(.active:not(.my-navlink-dark)) {
  background-color: rgb(49, 49, 49);
  border: 1px solid rgb(49, 49, 49);
}

.active:not(.my-navlink-dark), .my-navlink:hover {
  border-bottom: 1px solid black;
}

.my-side-nav {
  z-index: 1;
  right: 0;
  background-color: #000000;
  overflow-x: hidden;
  margin-top: 65px;
  position: fixed;
}

.my-navlink-mobile {
  background-color: black;
  color: white;
}

.my-navlink-mobile:hover, .active:not(.active:not(.my-navlink-mobile)) {
  color: #c9e265;
}

nav.my-side-nav ul li {
  list-style-type: none;
}
