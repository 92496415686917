
.content {
  height: calc(100vh - 73px - 4rem);
}

.footer {
  background-color: black;
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  height: 4rem; /* Footer height */
  color: #eee;
}

.footerText {
  margin: auto;
}
