
.root {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    display: flex;
    flex: {
        direction: column;
        wrap: nowrap;
    }
}

.editDisplay {
  border: 0.1px solid #6B8E23;
  box-shadow: 0 0 10px rgba(107, 142, 35, 0.5);
  background-color: #FAFAD2;
  transition: border-color 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.editDisplay:hover {
  border-color: #ADFF2F;
  box-shadow: 0 0 15px rgba(173, 255, 47, 0.8);
}
