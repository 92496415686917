.medium-text {
  font-size: 30px;
}

.publico-demo-iframe-container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.publico-demo-iframe {
  width: min(1280px, 100% - 2rem);
  height: calc(90vh - 73px - 2rem);
  padding-bottom: 25px;
  padding-top: 3px;
}
