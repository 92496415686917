
.search {
    display: flex;
    justify-content: center;
}

.card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #e6f7ff;
  }

.scrollableContainer {
  height: calc(110vh - 300px);
  overflow-y: auto;
  padding-bottom: 10px;
}


.deleteButton {
  background-color: #f0a88c;
  color: #123212;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #ce967a;
    color: #544040;
  }
}
.openButton {
  justify-content: center;
  background-color: #c3f08c;
  color: #123212;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #aac26f;
    color: #2d2222;
  }
}

.sortTitle {
  width: 200px;
  font-size: 1.3rem;
}

.sortSelect {
  width: 200px;
  font-size: 1rem;
  margin-right: 8px;
}

@media screen and (max-width: 3000px) {
  .deleteButton {
    padding: 8px 16px;
    margin-left: 10px;
  }
  .openButton {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 1840px) {
  .deleteButton {
    width: 70%;
    margin-left: 28%;
    padding: 8px 2px;
  }
  .openButton {
    width: 70%;
    margin-left: 28%;
    padding: 8px 2px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 980px) {
  .deleteButton {
    padding: 8px 16px;
    margin-left: 0%;
    width: auto;
  }
  .openButton {
    padding: 8px 16px;
    margin-left: 0%;
    width: auto;
  }
}


@media screen and (max-width: 640px) {
  .deleteButton {
    width: 100%;
    margin-left: 8%;
    padding: 8px 2px;
  }
  .openButton {
    width: 100%;
    margin-left: 8%;
    padding: 8px 2px;
  }
}
