@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// min-vh-100 overflow-hidden position-relative bg-light flex-no-wrap border border-5 border-primary
.root {
    height: 98.7vh;
    width: 100vw;
    background-color: var(--bs-light);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-height: 85%;
    max-height: 85%;
    width: 100%;
    padding: 0 1rem;
}

@include media-breakpoint-down(md) {
    .content {
        justify-content: flex-start;
    }
}
@include media-breakpoint-down(lg) {
    .content {
        padding-top: 5rem;
    }
}
