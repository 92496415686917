.text {
    margin: 0 !important;
    white-space: pre-line;
}

.listItems {
    list-style-type: none; 
    padding-left: 0;
  }
  
.listItems li:before {
    content: '\2022'; 
    color: #008000;
    display: inline-block;
    width: 1em; 
    margin-left: -1em; 
  }