.schedule-demo-container {
  max-width: 700px; /* Adjust width as necessary */
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
}

.schedule-demo-container h1 {
  margin-bottom: 2rem;
}

/* Style for input and textarea elements */
.schedule-demo-container input,
.schedule-demo-container textarea {
  width: 100%; /* Make sure the input takes the full width of its parent */
  margin-bottom: 1rem; /* Add some space below each input */
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Style for the submit button */
.schedule-demo-container input[type="submit"] {
  background-color: #c9e265; /* Use your brand color */
  color: #333;
  font-weight: bold;
  border: none;
  padding: 1rem;
  margin-top: 20px; /* Space between the last input and the submit button */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px;
}

/* Add hover effect for the submit button */
.schedule-demo-container input[type="submit"]:hover {
  background-color: #b0b957;
  color: #fff;
}

/* Style for the form response message */
.form-response-message {
  margin-top: 20px; /* Space after the submit button or form */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  color: #333;
  text-align: center;
}
