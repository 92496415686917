@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$light:      #f8f8f8;
$dark:       #0f0a2e;
$primary:    #cae364;
$secondary:  #734EA0;
$info:       #2778F9;
$success:    #3DDB74;
$warning:    #FFE01C;
$danger:     #FF5E66;
$primary-light: #e9f5ae;
$primary-dark: #446b1c;
// merge with existing $theme-colors map


$theme-colors: map-merge(
  $theme-colors, (
    "light": $light,
    "dark": $dark,
    "primary": $primary,
    "primary-light": $primary-light,
    "primary-dark": $primary-dark,
    "secondary": $secondary,
    "info": $info,
    "success": $success,
    "warning": $warning,
    "danger": $danger,
  ),
);

// set changes
